<template>
  <validation-observer ref="VFormVerif">
    <b-form @submit.prevent="doSubmit">
      <div class="row">
        <div class="col-md-4" v-if="(isParent.rowEdit.arantf_elektroterapi||[]).length">
          <div class="wrap_line">
            <h3>Elektroterapi</h3>
            <div>
              <ul class="mb-0 pl-3">
                <li v-for="(v,k) in (isParent.rowEdit.arantf_elektroterapi||[])" :key="k+'elektroterapi'">
                  <span v-if="v=='LE'" class="align-middle">{{isParent.rowEdit.arancptlf_elektroterapi_text||"-"}}</span>
                  <span v-else class="align-middle">{{v||"-"}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="(isParent.rowEdit.arantf_mekanoterapi||[]).length">
          <div class="wrap_line">
            <h3>Mekanoterapi</h3>
            <div>
              <ul class="mb-0 pl-3">
                <li v-for="(v,k) in (isParent.rowEdit.arantf_mekanoterapi||[])" :key="k+'mekanoterapi'">
                  <span v-if="v=='LM'" class="align-middle">{{isParent.rowEdit.arantf_mekanoterapi_text||"-"}}</span>
                  <span v-else class="align-middle">{{v||"-"}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="(isParent.rowEdit.arantf_latihan||[]).length">
          <div class="wrap_line">
            <h3>Latihan</h3>
            <div>
              <ul class="mb-0 pl-3">
                <li v-for="(v,k) in (isParent.rowEdit.arantf_latihan||[])" :key="k+'latihan'">
                  <span v-if="v=='LL'" class="align-middle">{{isParent.rowEditarantf_latihan_text||"-"}}</span>
                  <span v-else class="align-middle">{{v||"-"}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <span v-if="!((isParent.rowEdit.arantf_elektroterapi||[]).length + (isParent.rowEdit.arantf_mekanoterapi||[]).length + (isParent.rowEdit.arantf_latihan||[]).length)" >Tidak ada Tindakan Fisioterapi</span>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="text-right">
          <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
          <button type="submit" class="btn btn-primary">Verifikasi Data <i class="icon-paperplane ml-2"></i> 
          </button>
          </div>
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  methods: {
    doSubmit(){
      this.$refs['VFormVerif'].validate().then(success => {  
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'verif-data'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.isParent.loadingOverlay = true
            Gen.apiRest(
            "/do/" + this.isParent.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(result => {
                  this.isParent.openModal = false
                  this.isParent.apiGet()
                })
            }).catch(err => {
                this.isParent.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.isParent.doSetAlertForm(err)
            })
          })
        }
      })
    },
  },
  mounted() {
    console.log(this.isParent)
  },
}
</script>